<template>
  <div>
    <router-view :key="$route.path"></router-view>
  </div>
</template>
<script>
export default {
  components: {},
  data: () => ({}),
  computed: {},

  methods: {},
  mounted() {},
};
</script>

<style scoped>
</style>